.contactName {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    justify-content: space-evenly;
    width: 100%;
    gap: 1.6rem;
    padding: 2rem 0rem;
}
@media (max-width:716px) {
    .contactName{
        justify-content: flex-start;
    }
  }

.contactName>div {
    box-sizing: border-box;
    min-width: 40rem;
    /* flex-grow: 1; */
   
    /* box-shadow: 0 0 4px 1px rgba(89, 89, 89, 0.526);   */
    padding: 1rem;

    transition: all 0.3s;
}
.contactName>div:hover{
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526); 
}
.contactName > div > span:nth-child(1) {
display: block;
text-align: center;
}
.contactName > div > a > span:nth-child(1) {
    display: block;
    text-align: center;
    padding-bottom: 1rem;
    }
.hours{
    gap:1rem;
    box-sizing: border-box;
}
.hours > div {
    box-sizing: border-box;   
    margin-right: auto;
    background: linear-gradient(100deg, #016fbd34,rgb(245, 245, 245));
    padding: 0.5rem;
    border-radius: 4px;
}
.adres > div  {
font-weight: 400;
   
}
.days > div:nth-child(1) {
    min-width: 4.8rem;
    opacity: 0.6;

}
.days > div:nth-child(2){
    width: 11rem;
    text-align: right;
    /* margin-left: auto; */
}

.adres{
    font-weight: 600;
}
.localPhone{
    display: block;
    font-weight: 600;
    padding: 0.5rem 0;
}
.contLocation{
    box-sizing: border-box;
    width: 100%;
    padding: 0 1rem; 

}
.contLocation > span{
    padding: 1rem;
    display: block;
    margin: 0 auto;
    transition: all 0.3s;
}
.contLocation > span:hover{
    /* box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526);  */
    cursor:default;
}

.contMap{
    margin: 2rem 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap:2rem;
    /* max-width: 500px; */
    width: 100%;
}
.map{
    width: 100%;
    border: 0;
    flex-grow: 1;
    min-width: 230px;
    max-height: 35rem;
    aspect-ratio: 16/9;
    border: 8px solid white;
    border-radius: 4px;
    transition: all 0.3s;
}
.map:hover{
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526); 
}