.contEq{
    box-sizing: border-box;
    flex-wrap: nowrap !important;
    gap:2rem;
    padding:1.5rem;
    /* justify-content: center; */
    width: 100%;
}
.contEq > img{   
    max-width: 350px;
    aspect-ratio: 4/3;
    border-radius: 5px;
}
.contEq > div{
    font-size: 2.6rem;
    position: relative;
    padding-left: 30px;
    /* flex-grow: 1; */
}
.contEq > div::before{
    top:1.5rem;    
    left: 15px;
    transform: translate(-50% , -50%);
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background: rgb(245, 245, 245);
    border-radius: 10px;
    box-shadow: inset 0px 0px 0px 4px #016FBD;
}
@media (max-width:716px) {
   .contEq{
    flex-wrap: wrap !important;
   }
   .contEq > img{
    max-width: 100%;
   }
  }