.contGallery{   
    align-items: flex-start;
    position: relative;
    max-width: 900px;
    width: 100%;
    max-height: 600px;  
    aspect-ratio: 16/9;
    /* overflow: clip; */
    border-radius: 3px;
    transition: all 0.3s;
 
    
}
.contGallery:hover{
    box-shadow: 0 0 8px 2px rgba(89, 89, 89, 0.526);
}
.contGallery > img:nth-child(even){
   position: absolute;  
    width: 100.1%;
   height: 100%;
   right: 0;
    transition: all 0.6s linear;   
    /* object-fit: fill; */
    
}
.contGallery > img:nth-child(odd){
    position: absolute;  
     width: 100.1%;
    height: 100%;
    left: 0;
     transition: all 0.6s linear;   
     /* object-fit: fill; */
     
     
 }
.contButton{
position: absolute;
bottom: 20px;
left:50%;
transform: translateX(-50%);
}
.contButton > button {
    font-size: 2rem;
    background-color: transparent;
    color: rgb(255, 255, 255);
    border: 1.5px solid white;
    padding: 1.2rem 2.8rem;
    border-radius: 4.4rem;
    transition: all 0.4s;
    text-transform: uppercase;
    text-shadow: 0.1rem 0.1rem 0.4rem rgb(255, 255, 255) ;
    opacity: 0.6;
}
.contButton > button:hover {
    box-shadow: 0px 0px 10px 1px rgba(255, 255, 255, 1), inset 0px 0px 10px 1px rgba(255, 255, 255, 1) ;
    text-shadow: 0.1rem 0.1rem 0.8rem rgb(255, 255, 255) ;
    cursor: pointer;
    opacity: 1;   
}
.numberOfPage{
    position: absolute;
    top:8px;
    right:15px;
    color:white;
    text-shadow: 0.1rem 0.1rem 0.4rem rgb(255, 255, 255) ;
    font-size: 2rem; 
    /* font-family: 'Courier New', Courier, monospace; */
}
.contButton > button > span { 
    transform: translateX(0);
    transition: all 0.4s;
}
.contButton > button:hover > span {
    /* transform: translateX(1rem); */
    animation: arrow 2s infinite linear;
    animation-fill-mode:forwards;
}
@keyframes arrow {
    0% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(1rem);
    }
   45%{
        opacity: 0;
    }
    50% {
        transform: translateX(-1rem);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}
.string{
    box-sizing: border-box;
    margin-right: 9.5rem;
    /* max-width: 35rem; */
    position: absolute;
    bottom: 10rem;
    left: 2.4rem;
    opacity: 1;
    transform: translateX(15px);    
    transition: transform 1s , opacity 1s;
    transition-delay: 0.7s ;
    font-size: 2rem;
    line-height: 3.2rem;
    color: rgb(255, 255, 255);
    text-shadow: 0.1rem 0.1rem 0.4rem rgb(255, 255, 255) ;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.opacityZero{
    position: absolute;
    bottom: 60px;
    left: 40px;
    opacity: 0;
    transform: translateX(0);
   
}
@media (max-width:716px) {
    .string{
        bottom: 4rem;
        left:1rem
    }
    .contButton{
        left:auto;
        right: 1rem;
        bottom: 1rem;
        transform: translateX(0);
    }
    .contButton > button {
        padding: 1.2rem 1.6rem;
    }
  }

/* .one{
    position: absolute;
    bottom: 0;    
    left:50%;
    transform: translateX(-50%);
}
.two{
    position: absolute;
} */
.zero{
    width: 0 !important;
    height: 500px;
}