.cont_img {    
    max-width: 300px;
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;   
}
.cont_tooth {   
    width: 100px;   
    min-width: 100px;
    height: 200px;
    position: relative;
    overflow: hidden;   
}
.cont_tooth > div > span {    
    opacity: 0.8;
    max-width: 90px !important;
    max-height: 90px !important;
}
.mainName{
    display: block;
}
.mainName >div:nth-child(2){
    padding-top: 10px;
}
.empty_space {
    flex-grow: 1;    
}
.cont_img img {
    position: absolute;
    left:0;    
    height: 100%;
}
.rel{
    position: relative;
}
.header{
    position:absolute;
    top:10px;
    left:110px;
    z-index: 1;
}
.localNumbers{    
        position:absolute;
        top:10px;
        left:510px;
        z-index: 1;    
}
.localNumbersMobile{
    background-color: #9d9d9d;
    padding: 0 2px;
    line-height: 20px;
}
.menuIco{
    font-size: 90px;
}
.toTop{
    text-align: center;
    line-height: 45px;
    width: 45px;
    height: 45px;
    position: fixed;
    bottom:2rem;
    /* left:min(calc(100vw - 6.6rem) , calc(1000px - 6.6rem)); */
    left:min( calc(50% + 500px - 3.3rem) , calc(50% + 50vw - 3.3rem));
    transform: translateX( calc(-50% + 138px) );
    z-index: 100;
    transition: all 0.7s linear;
    opacity: 0;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
            /* background: rgba(140, 140, 140, 0.296);
            border-radius: 25px; */
}
.toTop:hover{
    cursor: pointer;
}
.toTopVisible{
    transform: translateX(-50%) rotateZ(-360deg) ;
    opacity: 0.7;
}
.displayBlock{
    display: block;   
}
.displayNoneTop{    
    animation: 1s blockAnim linear;
    animation-fill-mode: forwards;
}
@keyframes blockAnim {
    0%{
        height: 45px;
        width: 45px;
    }
    95%{
        height: 45px;
        width: 45px;
    }
    100%{
        height: 0;
        width: 0;
    }
}
.icoTop{
    font-size: 45px;
    max-width: 48px !important;
    max-height: 48px !important;
}




/* 





.aa{
max-width: 200px;
    line-height: 28px;
    color:transparent;
    background: linear-gradient(to right, orange 50%, black 50%);
    background-size: 200%;
    background-position:  100% 0 ;
    background-clip: text; 
    font-size: 22px;
    font-weight: 700;
}
.material-symbols-outlined{
    font-size: 28px;
    color:transparent;
    line-height: 30px;   

    
}
.aa:hover{
    cursor:default;
    background-position: 0 100%;
    transition-duration: 1s;
}
.b{
    width: 10px;
    height: 10px;
    color: transparent;
} */
