nav a {
    font-family: Montserrat;
    color: black;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 28px;

    color:transparent;
    background: linear-gradient(to right, orange 50%, black 50%);
    background-size: 210%;
    background-position:  100% 0 ;
    background-clip: text;
    transition-duration: 0.5s;

}
nav a:hover{
    cursor:pointer;
    background-position: 0 100%;
    transition-duration: 0.5s;
}

nav .active{
    cursor:pointer;
    background-position: 0 100%;
    transition-duration: 0.5s;
}
nav .active > .line{
    cursor:pointer;
    background-position: 0 100%;
    transition-duration: 0.5s;
}
nav .line{
    height: 4px;
    width: 100%;
    background: linear-gradient(to right, orange 50%,  rgb(245, 245, 245) 50%);
    background-size: 210%;
    background-position:  100% 0 ;   
    transition-duration: 0.5s;  
}
nav a:hover > .line{
    background-position:  0 100% ;
    transition-duration: 0.5s;    
}
nav .mobile{ 
    position: absolute;
    top:100px;
    left:0px;
    background: rgb(245, 245, 245);
    padding: 20px;
    z-index: 10;   
    max-width: 28rem;
    transform: translateX(-101%);
    transition-delay: 0.5s;
    transition-duration: 0.5s;
}
nav .mobileActive{
    position: absolute;
    top:100px;
    left:0px;
    background: rgb(245, 245, 245);
    padding: 20px;
    z-index: 10;   
    max-width: 28rem;
    transform: translateX(0);
    transition-duration: 0.5s;
}
nav .mobile a{
    font-size: 3rem;    
    padding-top: 20px;
    padding-bottom: 20px;
    gap:10px;
  
}
nav .mobileActive a{
    font-size: 3rem;    
    padding-top: 20px;
    padding-bottom: 20px;
    gap:10px;
  
}
.navIco{
    font-size: 2.8rem;
}
.backgroundMobile{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: grey;
    z-index: 9;
    opacity: 0.8;
}
/* nav .mobile::after{
    position: absolute;    
    z-index: 1;
    right: 0;    
    top:0px;
    content: '';
    background-color: red;
    height: 100%;
    width: 95%;
    transform: translateX(100%);
} */
/* nav .mobile::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(245, 245, 245);
    inset: 0;
    
    z-index: -2;
    opacity: 0.5;
} */